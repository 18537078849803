/* Hide the up and down arrows on number inputs */
.items-per-page-input::-webkit-outer-spin-button,
.items-per-page-input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.items-per-page-input[type=number] {
  -moz-appearance: textfield;
}

/* Ensure pagination has same background as table */
.pagination-container {
  background-color: white;
}